/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
// import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11' 
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

   
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Orders'
        fontIcon='bi-archive'
        icon='element-plus'
      >

        <SidebarMenuItem to='admin/manage-orders/orders' title='View orders' hasBullet={true} />
        {/* <SidebarMenuItem to='/manage-orders' title='Manage Orders' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>

    </>
  )
}

export { SidebarMenuMain }
