import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = 'https://api.mechanicnow.in'


export const GET_USER_BY_ACCESSTOKEN_URL = 'https://api.mechanicnow.in/api/vendor/verify_token'
export const LOGIN_URL = 'https://api.mechanicnow.in/api/vendor/login'
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const POST_BRAND_NAME  = `${API_URL}/api/admin/add-brand`
export const GET_BRAND_NAME  = `${API_URL}/api/admin/get-brand`
export const DELETE_BRAND_NAME  = `${API_URL}/api/admin/delete-brand`
export const UPDATE_BRAND_NAME  = `${API_URL}/api/admin/update-brand`
export const POST_MODEL_NAME = `${API_URL}/api/admin/add-model`
export const GET_MODEL_NAME = `${API_URL}/api/admin/get-model`
export const GET_MODEL_NAME_BY_BRAND = `${API_URL}/api/admin/get-model`
export const POST_VARIENT_NAME = `${API_URL}/api/admin/add-varient`
export const GET_VARIENT_NAME = `${API_URL}/api/admin/get-varient`
export const GET_VARIENT_NAME_BY_MODEL = `${API_URL}/api/admin/variants`
export const POST_SERVICE_CATEGORY = `${API_URL}/api/admin/submitServicForm`
export const SERVICE_CATEGORY_DATA = `${API_URL}/api/admin/service-categories-data`
export const CATEGORY_TABLE_DATA = `${API_URL}/api/admin/get-service`
export const ADD_SERVICES = `${API_URL}/api/admin/add-service`
export const GET_SERVICES = `${API_URL}/api/admin/get-services`
export const GET_SERVICES_MODAL = `${API_URL}/api/admin/get-service-modal`
export const UPDATE_SERVICES = `${API_URL}/api/admin/update-services`
export const OrderDetailsSave = `${API_URL}/api/admin/view-order-details`
export const InventorySave = `${API_URL}/api/admin/inventory-details`
export const OrderHealthCardSave = `${API_URL}/api/admin/order-health-card`
export const OrderEstimateSave = `${API_URL}/api/admin/order-estimate`
export const OrderPaymentsApi = `${API_URL}/api/admin/add-payments`

export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
