/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
 
  CardsWidget20,
 
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'> */}
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Total Orders'
          color='#00A1FA'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
          <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Total Orders'
          color='#00A1FA'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
    
      
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
